.navbar {
  overflow: hidden;
  background-color: #333;
}

/* Navigation links */
.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* The subnavigation menu */
.subnav {
  float: left;
  overflow: hidden;
}

/* Subnav button */
.subnav .subnavbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* Add a red background color to navigation links on hover */
.navbar a:hover,
.subnav:hover .subnavbtn {
  background-color: black;

}

/* Style the subnav content - positioned absolute */
.subnav-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: black;
  width: 100%;
  z-index: 10000;
}

/* Style the subnav links */
.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
}

/* Add a grey background color on hover */
.subnav-content a:hover {
  background-color: #eee;
  color: black;
}

/* When you move the mouse over the subnav container, open the subnav content */
.subnav:hover .subnav-content {
  display: block;
}

@import url("https://fonts.googleapis.com/css?family=Inconsolata|Merriweather");

body {
  font-family: "Inconsolata", monospace;
  margin: 0;
  width: 100%;
  background-color: #1A2027
}

button {
  cursor: pointer;
}

.cont {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.contVip {
  display: flex;
  flex: 50%;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.imgdimVip {
  width: 380px;
  height: 180px;
}


.imgdim {
  width: 180px;
  height: 180px;
  object-fit: cover;
}


@media only screen and (max-width:340px) {
  .imgdim {
    width: 140px;
    height: 140px;
  }

}

@media only screen and (max-width:375px) {
  .imgdim {
    width: 93px;
    height: 93px;
  }

}


@media only screen and (min-width:376px) {
  .imgdim {
    width: 100px;
    height: 100px;
  }

}

@media only screen and (min-width:390px) {
  .imgdim {
    width: 130px;
    height: 130px;
  }

  @media only screen and (min-width:412px) {
    .imgdim {
      width: 134px;
      height: 134px;
    }
  }

  @media only screen and (min-width:414px) {
    .imgdim {
      width: 135px;
      height: 135px;
    }
  }

  @media only screen and (min-width:430px) {
    .imgdim {
      width: 140px;
      height: 140px;
    }
  }

  @media only screen and (min-width:992px) {
    .imgdim {
      width: 170px;
      height: 170px;
    }
  }

  @media only screen and (min-width:1200px) {
    .imgdim {
      width: 185px;
      height: 185px;
    }
  }

}